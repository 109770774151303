<template>
  <section name="comportamento">
    <v-card elevation="2" rounded class="ma-8 pa-4">
      <v-row>
        <v-col cols="12">
          <Crud
            title="Gestão de Comportamentos"
            buttonAddItemText="Novo Comportamento"
            searchLabelText="Pesquisar na lista"
            :searchTextField.sync="searchTextField"
            :formTitle="nameForm"
            :callBackModalCancelar="fechar"
            :dialog.sync="dialog"
            :callBackModalSalvar="salvar"
            :dialogDelete.sync="dialogDelete"
          >
            <template slot="data-content">
              <v-row class="mt-5">
                <!-- FILTRO CAMPOS -->
              </v-row>
            </template>
            <template slot="data-table">
              <v-data-table
                :headers="headers"
                :items="comportamentos"
                class="elevation-5"
                :loading="loadingTable"
              >
                <!-- ACTIONS -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingVisualizar[`${item.id}`]"
                      >
                        <v-icon
                          class="actions"
                          @click="toggleModalLateral(item)"
                        >
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingEditar[`${item.id}`]"
                      >
                        <v-icon class="actions" @click="editar(item)">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingDelete[`${item.id}`]"
                      >
                        <v-icon class="actions" @click="remover(item)">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-switch
                    class="mt-0 pt-0"
                    v-model="item.status"
                    @click="mudarStatus(item)"
                    :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                    color="success"
                    value="A"
                    hide-details
                    :loading="loadingStatus[`${item.id}`]"
                  ></v-switch>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ item.created_at | dateFormatBr() }}
                </template>
              </v-data-table>
            </template>
            <!--------------- MODAL DE DELETE --------------------------------------------- -->
            <template slot="modalDelete/titleModal"
              >Excluir o registro?</template
            >
            <template slot="modalDelete/contentModal">
              <p>
                Atenção! Você está prestes a realizar uma ação que não pode ser
                desfeita.
              </p>
              <p>Você realmente deseja excluir esse registro?</p>
            </template>
            <template slot="modalDelete/actionsModal">
              <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
              <v-btn
                :loading="loadingButtonDelete"
                color="error"
                @click="remover()"
                >Excluir</v-btn
              >
              <v-spacer class="d-none d-md-block"></v-spacer>
            </template>
            <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
            <template slot="contentModalCrud">
              <v-container fluid>
                <v-row class="ma-3" wrap>
                  <!-- competencia -->
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      ref="competencia"
                      label="Competência"
                      placeholder="Selecione a Competência do Comportamento"
                      :items="competencias"
                      dense
                      outlined
                      return-object
                      v-model="comportamento.competencia"
                      item-text="nome"
                      item-value="id"
                      data-vv-as="competencia"
                      persistent-hint
                      :hint="errors.first('competencia')"
                      :error="
                        errors.collect('competencia').length ? true : false
                      "
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="competencia"
                      name="competencia"
                    >
                      <template v-slot:selection="data">
                        <template>
                          <v-icon class="pr-3">{{ data.item.icone }}</v-icon>
                          <v-list-item-content>
                            {{ data.item.nome }}
                          </v-list-item-content>
                        </template>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-icon class="pr-3">{{ data.item.icone }}</v-icon>
                          <v-list-item-content>
                            {{ data.item.nome }}
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- filial -->
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      ref="filial"
                      label="Unidade"
                      placeholder="Selecione a unidade do usuário"
                      :items="filiais"
                      dense
                      outlined
                      return-object
                      v-model="comportamento.filial"
                      item-text="nome"
                      item-value="id"
                      data-vv-as="filial"
                      persistent-hint
                      :hint="errors.first('filial')"
                      :error="errors.collect('filial').length ? true : false"
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="filial"
                      name="filial"
                    ></v-select>
                  </v-col>
                  <!-- descrição -->
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      ref="descricao"
                      label="Comportamento"
                      outlined
                      dense
                      v-model="comportamento.descricao"
                      data-vv-as="descrição"
                      persistent-hint
                      :hint="errors.first('descricao')"
                      :error="errors.collect('descricao').length ? true : false"
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="descricao"
                      name="descricao"
                    ></v-textarea>
                  </v-col>
                  <!-- previsualização -->
                  <v-col cols="12" sm="12" md="12">
                    <h3 style="color: rgba(0, 0, 0, 0.54)">Previsualização</h3>
                  </v-col>
                  <div v-if="comportamento.descricao" class="row">
                    <v-col cols="12" sm="12" md="12">
                      <h4 style="color: rgba(0, 0, 0, 0.54)">
                        Autoavaliação - Como você se avalia no comportamento:
                      </h4>
                      <v-card
                        style="background-color: white !important"
                        elevation="4"
                      >
                        <v-container>
                          {{ comportamento.descricao }}
                          <v-rating
                            v-model="rating"
                            color="yellow darken-3"
                            background-color="grey darken-1"
                            half-increments
                            readonly
                            hover
                            small
                          ></v-rating>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <h4 style="color: rgba(0, 0, 0, 0.54)">
                        Avaliação de Pares - Como você avalia seu colega NOME no
                        comportamento:
                      </h4>
                      <v-card
                        style="background-color: white !important"
                        elevation="4"
                      >
                        <v-container>
                          {{ comportamento.descricao }}
                          <v-rating
                            v-model="rating"
                            color="yellow darken-3"
                            background-color="grey darken-1"
                            half-increments
                            readonly
                            hover
                            small
                          ></v-rating>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <h4 style="color: rgba(0, 0, 0, 0.54)">
                        Avaliação de Líder - Como você avalia seu líder NOME no
                        comportamento:
                      </h4>
                      <v-card
                        style="background-color: white !important"
                        elevation="4"
                      >
                        <v-container>
                          {{ comportamento.descricao }}
                          <v-rating
                            v-model="rating"
                            color="yellow darken-3"
                            background-color="grey darken-1"
                            half-increments
                            readonly
                            hover
                            small
                          ></v-rating>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <h4 style="color: rgba(0, 0, 0, 0.54)">
                        Avaliação de Cliente - Como você avalia seu cliente NOME
                        no comportamento:
                      </h4>
                      <v-card
                        style="background-color: white !important"
                        elevation="4"
                      >
                        <v-container>
                          {{ comportamento.descricao }}
                          <v-rating
                            v-model="rating"
                            color="yellow darken-3"
                            background-color="grey darken-1"
                            half-increments
                            readonly
                            hover
                            small
                          ></v-rating>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <h4 style="color: rgba(0, 0, 0, 0.54)">
                        Avaliação de Subordinados - Como você avalia seu
                        subordinado NOME no comportamento:
                      </h4>
                      <v-card
                        style="background-color: white !important"
                        elevation="4"
                      >
                        <v-container>
                          {{ comportamento.descricao }}
                          <v-rating
                            v-model="rating"
                            color="yellow darken-3"
                            background-color="grey darken-1"
                            half-increments
                            readonly
                            hover
                            small
                          ></v-rating>
                        </v-container>
                      </v-card>
                    </v-col>
                  </div>
                </v-row>
              </v-container>
            </template>
          </Crud>
        </v-col>
      </v-row>
      <ModalLateral
        :show="modalLateral.show"
        :hide="toggleModalLateral"
        :title="modalLateral.title"
        :headers="headerModalLateral"
        :item="modalLateral.item"
      >
        <template v-slot:[`item.filial`]="{ item }">
          <span>{{ item.filial }}</span>
        </template>
        <template v-slot:[`item.competencia`]="{ item }">
          <span
            v-if="
              item.competencia &&
              item.competencia.nome &&
              item.competencia.icone
            "
            ><v-icon>{{ item.competencia.icone }}</v-icon>
            {{ item.competencia.nome }}</span
          >
        </template>
        <template v-slot:[`item.icone`]="{ item }">
          <v-icon>{{ item.icone }}</v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            :style="'background-color: ' + getColor(item.status)"
            small
            dark
          >
            {{
              item.status == "A" || item.status == "Ativo" || item.status == ""
                ? "Ativo"
                : "Inativo"
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <span
            >{{ item.created_at | dateFormatBr() }}
            {{ item.created_at | timeFormatBr() }}</span
          >
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <span
            >{{ item.updated_at | dateFormatBr() }}
            {{ item.updated_at | timeFormatBr() }}</span
          >
        </template>
      </ModalLateral>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";

export default {
  name: "Comportamentos",
  components: {
    Crud,
    ModalLateral,
  },
  data: () => ({
    logotipo: logo,
    nameForm: "Novo Comportamento",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    comportamento: {
      id_filial: "",
      id_competencia: "",
      filial: {},
      competencia: {},
      descricao: "",
      padrao: "personalizado",
      status: "A",
    },
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    searchTextField: "",
    dialog: false,
    indexEdicao: -1,
    loadingTable: false,
    loadingVisualizar: [],
    loadingEditar: [],
    loadingDelete: [],
    loadingStatus: [],
    headers: [
      { text: "Descrição", align: "start", value: "descricao" },
      { text: "Competência", value: "competencia.nome" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Descrição", align: "start", value: "descricao" },
      { text: "Competência", value: "competencia" },
      { text: "Filial", value: "filial.nome" },
      { text: "Data de criação", value: "created_at" },
      { text: "Data de atualização", value: "updated_at" },
      { text: "Status", value: "status" },
    ],
    rating: 0.0,
    dialogDelete: false,
    loadingButtonDelete: false,
    item: [],
  }),

  created() {
    const dataCompetencias = {
      columns: ["id", "nome", "icone"],
      validate: [["status", "A"]],
    };
    const dataFiliais = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };

    this.$store.dispatch("avd/initializeComportamento");
    this.$store.dispatch("avd/findByColumnsCompetencias", dataCompetencias);
    this.$store.dispatch("filiais/findByColumns", dataFiliais);
  },

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === -1
        ? "Cadastrar comportamento"
        : "Editar comportamento";
    },
    comportamentos: {
      get() {
        return this.$store.getters["avd/getComportamentos"];
      },
      set(value) {
        return value;
      },
    },
    competencias: {
      get() {
        return this.$store.getters["avd/getCompetencias"];
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["avd/getLoading"];
      },
      set(value) {
        return value;
      },
    },
  },

  methods: {
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loadingTable = true;
          this.dialog = false;
          let url =
            this.indexEdicao === -1
              ? "/comportamentos"
              : "/comportamentos/" + this.comportamento.uuid;
          let method = this.indexEdicao === -1 ? "POST" : "PUT";
          let sucesso =
            this.indexEdicao === -1
              ? "Comportamento cadastrado com sucesso."
              : "Comportamento atualizado com sucesso.";
          let erro =
            this.indexEdicao === -1
              ? "Erro ao salvar comportamento."
              : "Erro ao atualizar comportamento.";

          this.comportamento.id_filial =
            this.comportamento.filial && this.comportamento.filial.id
              ? this.comportamento.filial.id
              : null;
          this.comportamento.id_competencia =
            this.comportamento.competencia && this.comportamento.competencia.id
              ? this.comportamento.competencia.id
              : null;
          if(this.comportamento.id_filial === 0) {
            this.comportamento.id_filial = null
            this.comportamento.filial = {id:null,nome:'Matriz'}
          }
          this.comportamento.padrao = "personalizado"
          let comportamentoItem = this.comportamento;
          comportamentoItem._method = method;
          comportamentoItem.url = url;
          this.$store
            .dispatch("avd/saveComportamento", comportamentoItem)
            .then((response) => {
              this.indexEdicao === -1
                ? this.comportamentos.push(response)
                : Object.assign(
                    this.comportamentos[this.indexEdicao],
                    this.comportamento
                  );
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "green",
                mensagem: sucesso,
                duracao: 3000,
              });
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                cor: "red",
                mensagem: erro,
                duracao: 3000,
              });
            })
            .finally(() => {
              this.fechar();
            });
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem: "Preencha todo formulário antes de salvar!",
            duracao: 3000,
          });
        }
      });
    },
    editar(item) {
      if(item.id_filial === null && item.filial === {}) {
        item.filial = {id:null,nome:'Matriz'}
      }
      this.indexEdicao = this.comportamentos.indexOf(item);
      this.comportamento = Object.assign({}, item);
      this.dialog = true;
    },
    fechar() {
      this.loadingTable = false;
      this.dialog = false;
      this.comportamento = {
        id_filial: "",
        id_competencia: "",
        filial: {},
        competencia: {},
        descricao: "",
        padrao: "personalizado",
        status: "A",
      };
      this.editedIndex = false;
    },
    mudarStatus(item) {
      this.loadingStatus[item.id] = true;
      this.loadingTable = true;

      this.indexEdicao = this.comportamentos.indexOf(item);
      this.comportamento = Object.assign({}, item);
      this.comportamento.status =
        this.comportamento.status == null ? "I" : this.comportamento.status;
      if (this.comportamento.id_filial == null) {
        this.comportamento.id_filial = 0;
        this.comportamento.filial = { id: null, nome: "Matriz" };
      }
      let comportamentoItem = this.comportamento;
      comportamentoItem._method = "PUT";
      comportamentoItem.url = "/comportamentos/" + this.comportamento.uuid;
      this.$store
        .dispatch("empresas/salvarEmpresaComportamento", comportamentoItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            Object.assign(
              this.comportamentos[this.indexEdicao],
              this.comportamento
            );
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Competência atualizada com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar competência.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingStatus[item.id] = false;
          this.loadingTable = false;
          this.indexEdicao = -1;
          this.comportamento = Object.assign(
            {},
            {
              id_filial: "",
              id_competencia: "",
              filial: {},
              competencia: {},
              descricao: "",
              padrao: "personalizado",
              status: "A",
            }
          );
        });
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.comportamentos.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("avd/deleteComportamento", this.item.uuid)
          .then((response) => {
            if (response.data === true) {
              this.comportamentos.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    toggleModalLateral(item) {
      if(item.id_filial === null && item.filial === {}) {
        item.filial = {id:null,nome:'Matriz'}
      }
      this.modalLateral.title = "";
      this.modalLateral.item = this.modalLateral.show ? [] : item;
      this.modalLateral.show = this.modalLateral.show ? false : true;
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A" || status == "Ativo") return "green";
      else return "red";
    },
  },
};
</script>

<style scoped></style>
